import { Paragraph } from '@stage-ui/core'
import ParagraphTypes from '@stage-ui/core/content/Paragraph/types'

export const TextBlock = (props: ParagraphTypes.Props & { isDark?: boolean }) => {
  const { children, isDark, ...restProps } = props
  return (
    <Paragraph
      color={isDark ? 'surface' : 'onSurface'}
      mb="1.5rem"
      size="1.125rem"
      style={{
        opacity: 0.8,
        lineHeight: 1.8,
        maxWidth: '44rem',
        whiteSpace: 'pre-wrap',
      }}
      {...restProps}
    >
      {children}
    </Paragraph>
  )
}
