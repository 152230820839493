import timenote_app from '@assets/images/timenote_app.jpg'
import { LineTitle } from '@src/components/LineTitle'
import { Page } from '@src/components/Page'
import { TextBlock } from '@src/components/TextBlock'
import { Block, Flexbox, Paragraph } from '@stage-ui/core'

const S03CheckoutVideoChat = () => {
  return (
    <Block backgroundColor="surface" style={{ minHeight: '100vh' }}>
      <Page pt="7rem" pb="1rem">
        <LineTitle mb="15vh">Timenote.app</LineTitle>
        <Flexbox direction={['row', 'row', 'column']}>
          <Block flex={1}>
            <Paragraph size="l" weight={700}>
              Start easy
            </Paragraph>
            <TextBlock mb="6vh">
              Easily start tracking for all{'\n'}your projects just in one click
            </TextBlock>
            <Paragraph size="l" weight={700}>
              Activity Tracking
            </Paragraph>
            <TextBlock mb="6vh">
              Make time tracking easier{'\n'}and more efficient
            </TextBlock>
            <Paragraph size="l" weight={700}>
              Powerful reports
            </Paragraph>
            <TextBlock mb="6vh">
              Create summary reports{'\n'}directly from the app
            </TextBlock>
          </Block>
          {/* <Block
            w="20rem"
            h="20rem"
            backgroundColor="black"
            ml="20rem"
            style={{
              width: 0,
              height: 0,
              animation: `${rotateAnimation} 20s linear infinite`,
            }}
          /> */}
          <Block
            mt="-10rem"
            style={{
              background: `url(${timenote_app}) no-repeat`,
              backgroundSize: '100%',
              width: '35rem',
              height: '44rem',
            }}
          />
        </Flexbox>
      </Page>
    </Block>
  )
}

export default S03CheckoutVideoChat
